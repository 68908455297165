#register, .auth-register {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    min-height: 100%;
    // background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-wrapper {
        width: remReCalc(38.4rem);
        max-width: remReCalc(38.4rem);
        background: #FFFFFF;
        text-align: center;

        .logo {
            width: remReCalc(12.8rem);
            height: remReCalc(12.8rem);
            line-height: remReCalc(12.8rem);
            font-size: remReCalc(8.6rem);
            font-weight: 500;
            margin: remReCalc(3.2rem auto);
            color: #FFFFFF;
            border-radius: 2px;
        }

        .title {
            font-size: remReCalc(1.7rem);
        }

        form {
            width: 100%;
            text-align: left;

            .terms-conditions {
                font-size: remReCalc(1.3rem);
                .form-check {
                    font-size: remReCalc(1.3rem);

                    .form-check-label
                    {
                        padding-left: 0 !important;
                    }
                }
            }

            .submit-button {
                width: remReCalc(22rem);
            }
        }

        .login {
            font-weight: 500;
            font-size: remReCalc(1.3rem);

            .text {
            }

            .link {
            }
        }
    }
}

// RESPONSIVE
@include media-breakpoint-down(md) {

    #register, .auth-register {

        .form-wrapper {
            padding: remReCalc(1.6rem);
            width: 100%;

            form {

                .btn {
                    padding: 0 10px;
                    width: 100%;
                }
            }

            .btn {
                padding: 0 10px;
                &.google,
                &.facebook {
                    width: 90%;
                }
            }
        }
    }
}