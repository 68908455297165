@function convert-to-rem($value) 
{
	@if $value == auto
	{
		@return $value;
	}

	$value: $value / 1.6;
	@if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
	@return $value;
}

@function remReCalc($values) 
{
	$max: length($values);

	@if $max == 1 { @return convert-to-rem(nth($values, 1)); }

	$remValues: ();

	@for $i from 1 through $max 
	{
		$remValues: append($remValues, convert-to-rem(nth($values, $i)));
	}

	@return $remValues;
}