@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

.tpl-fuse
{
	@import "fuse/functions/functions";
	@import "fuse/md/main";
	
	// Apps
	@import "fuse/apps/mail/mail";
	@import "fuse/apps/contacts/contacts";
	@import "fuse/apps/file-manager/file-manager";
	@import "fuse/apps/todo/todo";
	@import "fuse/apps/chat/chat";
	@import "fuse/apps/dashboards/project/project";
	@import "fuse/apps/dashboards/server/server";
	@import "fuse/apps/e-commerce/products/products";
	@import "fuse/apps/e-commerce/product/product";
	@import "fuse/apps/e-commerce/orders/orders";
	@import "fuse/apps/calendar/calendar";
	
	// Pages
	@import "fuse/pages/profile/profile";
	@import "fuse/pages/auth/login";
	@import "fuse/pages/auth/login-v2";
	@import "fuse/pages/auth/register";
	@import "fuse/pages/auth/register-v2";
	@import "fuse/pages/auth/forgot-password";
	@import "fuse/pages/auth/reset-password";
	@import "fuse/pages/auth/lock-screen";
	@import "fuse/pages/coming-soon/coming-soon";
	@import "fuse/pages/maintenance/maintenance";
	@import "fuse/pages/errors/404/404";
	@import "fuse/pages/errors/500/500";
	@import "fuse/pages/search/search";
	
	// Components
	@import "fuse/components/charts/nvd3/nvd3";
	
	// Plugins
	@import "fuse/plugins/datatable";
	@import "fuse/plugins/responsive.dataTables";
	@import "fuse/plugins/fuse-aside";
	@import "fuse/plugins/custom-scrollbars";
	@import "fuse/plugins/nvd3";
}