/* ---------------------------------------------------------------- */
/*  Avatars
/* ---------------------------------------------------------------- */
.avatar {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    line-height: 4rem;
    border-radius: 50%;
    font-size: 1.7rem;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
    background-color: theme-color("primary");

    &.square {
        border-radius: 0;
    }

    &.small {
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
    }

    &.big {
        width: 7.2rem;
        min-width: 7.2rem;
        height: 7.2rem;
        line-height: 7.2rem;
    }

    &.huge {
        width: 9.6rem;
        min-width: 9.6rem;
        height: 9.6rem;
        line-height: 9.6rem;
    }
}