#login, .auth-login {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    min-height: 100%;
    // background: url('images/backgrounds/dark-material-bg.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-wrapper {
        width: remReCalc(38.4rem);
        max-width: remReCalc(38.4rem);
        background: #FFFFFF;
        text-align: center;

        .logo {
            width: remReCalc(12.8rem);
            height: remReCalc(12.8rem);
            line-height: remReCalc(12.8rem);
            font-size: remReCalc(8.6rem);
            font-weight: 500;
            margin: remReCalc(3.2rem auto);
            color: #FFFFFF;
            border-radius: 2px;
        }

        .title {
            font-size: remReCalc(1.7rem);
        }

        form {
            width: 100%;
            text-align: left;

            .remember-forgot-password {

                .form-check-label
                {
                    padding-left: 0 !important;
                }

                .remember-me {
                    font-size: remReCalc(1.3rem);
                }

                .forgot-password {
                    font-size: remReCalc(1.3rem);
                    font-weight: 500;
                }
            }

            .submit-button {
                width: remReCalc(22rem);
            }
        }

        .register {
            display: block !important;
            font-weight: 500;
            font-size: remReCalc(1.3rem);

            .text {
                margin: 0;
                margin-bottom: 5px;
            }

            .link {
            }
        }

        .separator {
            font-size: remReCalc(1.5rem);
            font-weight: 600;
            margin: remReCalc(2.4rem auto 1.6rem auto);
            position: relative;
            overflow: hidden;
            width: remReCalc(10rem);
            color: rgba(0, 0, 0, 0.54);
            text-align: center;

            .text {
                display: inline-block;
                vertical-align: baseline;
                position: relative;
                padding: remReCalc(0 0.8rem);
                z-index: 9999;

                &:before, &:after {
                    content: '';
                    display: block;
                    width: remReCalc(3rem);
                    position: absolute;
                    top: remReCalc(1rem);
                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                }

                &:before {
                    right: 100%;
                }

                &:after {
                    left: 100%;
                }
            }
        }

        .btn {

            &.google,
            &.facebook {
                width: remReCalc(19.2rem);
                text-transform: none;
                color: #FFFFFF;
                font-size: remReCalc(1.3rem);

                i {
                    color: #FFFFFF;
                    margin: remReCalc(0 .8rem 0 0);
                }
            }

            &.google {
                background-color: #D73D32;
            }

            &.facebook {
                background-color: rgb(63, 92, 154);
            }
        }
    }
}

// RESPONSIVE
@include media-breakpoint-down(md) {

    #login, .auth-login {

        .form-wrapper {
            padding: remReCalc(1.6rem);
            padding: remReCalc(2.4rem);
            width: 100%;

            form {

                .btn {
                    padding: 0 10px;
                    width: 100%;
                }

                .remember-forgot-password {
                    display: block;
                }
            }

            .btn {
                padding: 0 10px;
                &.google,
                &.facebook {
                    width: 90%;
                }
            }
        }
    }
}